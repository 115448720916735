import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const BackgroundImage2 = ({
  className = "",
  children,
  heroImage,
  zIndex = null,
  verticalJustify = "center",
}) => {
  const data = useStaticQuery(graphql`
    query {
      HeroImageQuery: file(relativePath: { eq: "headers/pelicanfalls.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const bgStyle = {
    zIndex: zIndex,
  }
  // Set ImageData.
  const defaultHeroImage = data.HeroImageQuery.childImageSharp
  // if heroImage isn't passed, use HeroImageQuery
  const { fluid } = heroImage ? heroImage : defaultHeroImage

  const backgroundFluidImageStack = [
    fluid,
    `linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))`,
  ].reverse()
  return (
    <BackgroundImage
      Tag="section"
      className={`w-full h-full bg-center  ${className}`}
      fluid={backgroundFluidImageStack}
      backgroundColor={`#ededed`}
      style={bgStyle}
    >
      <div className={`flex h-full justify-center items-${verticalJustify} `}>
        {children}
      </div>
    </BackgroundImage>
  )
}

export default BackgroundImage2
